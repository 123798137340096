import React from "react";

const Faqs = () => {
  return (
    <section id="faq" className="faq section">
      {/* Faq Section */}
      <section id="faq" className="faq section">
        {/* Section Title */}
        <div className="container section-title aos-init aos-animate" data-aos="fade-up">
          <h2>PROGRAM STRUCTURE</h2>
        </div>
        {/* End Section Title */}
        <div
          className="container"
          style={{ maxWidth: 1200, margin: "0 auto", padding: "0 15px" }}
        >
          <div
            className="row justify-content-center"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              className="col-lg-10 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay={100}
              style={{ flex: "0 0 83.333333%", maxWidth: "83.333333%" }}
            >
              <div
                className="faq-container"
                style={{
                  background: "#f9f9f9",
                  borderRadius: 8,
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  padding: 20,
                }}
              >
                <div
                  className="faq-item faq-active"
                  style={{
                    background: "#fff",
                    borderRadius: 5,
                    marginBottom: 10,
                    padding: 20,
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  }}
                >
                  <h3
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "1.5rem",
                      color: "#0a0a0a",
                    }}
                  >
                    <i
                      className="fas fa-tasks"
                      style={{ marginRight: 10, color: "#00008B" }}
                    />{" "}
                     Leadership and Innovation Management Training
                  </h3>
                </div>
                {/* End Faq item*/}
                <div
                  className="faq-item faq-active"
                  style={{
                    background: "#fff",
                    borderRadius: 5,
                    marginBottom: 10,
                    padding: 20,
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  }}
                >
                  <h3
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "1.5rem",
                      color: "#0a0a0a",
                    }}
                  >
                    <i
                      className="fas fa-chart-line"
                      style={{ marginRight: 10, color: "#00008B" }}
                    />{" "}
                    Benchmarking and Site Visits
                  </h3>
                </div>
                {/* End Faq item*/}
                <div
                  className="faq-item faq-active"
                  style={{
                    background: "#fff",
                    borderRadius: 5,
                    marginBottom: 10,
                    padding: 20,
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  }}
                >
                  <h3
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "1.5rem",
                      color: "#0a0a0a",
                    }}
                  >
                    <i
                      className="fas fa-users"
                      style={{ marginRight: 10, color: "#00008B" }}
                    />{" "}
                     Community of Practice Virtual EngagementTraining
                  </h3>
                </div>
                {/* End Faq item*/}
                <div
                  className="faq-item faq-active"
                  style={{
                    background: "#fff",
                    borderRadius: 5,
                    marginBottom: 10,
                    padding: 20,
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  }}
                >
                  <h3
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "1.5rem",
                      color: "#0a0a0a",
                    }}
                  >
                    <i
                      className="fas fa-lightbulb"
                      style={{ marginRight: 10, color: "#00008B" }}
                    />{" "}
                    Graduation and Certification
                  </h3>
                </div>
                {/* End Faq item*/}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Faq Section */}
      {/* Training Dates Section */}
      <div
        className="container mt-5"
        style={{
          backgroundColor: "#222",
          borderRadius: 8,
          padding: 40,
          color: "white",
        }}
      >
        <h3
          style={{
            textAlign: "center",
            marginBottom: 20,
            color: "white",
            fontSize: 24,
            fontWeight: "bold",
          }}
        >
          TRAINING DATES BY COUNTRY
        </h3>
        <div className="row">
          <div className="col-md-12">
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                color: "white",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      borderBottom: "2px solid #00008B",
                      padding: 10,
                      textAlign: "left",
                      fontSize: 16,
                    }}
                  >
                    Country
                  </th>
                  <th
                    style={{
                      borderBottom: "2px solid #00008B",
                      padding: 10,
                      textAlign: "left",
                      fontSize: 16,
                    }}
                  >
                    Training Date
                  </th>
                </tr>
              </thead>
              <tbody>
              <tr style={{ backgroundColor: "#222" }}>
                  <td style={{ padding: 10 }}>Uganda – Kampala</td>
                  <td style={{ padding: 10 }}>11th – 15th November 2024</td>
                </tr>
                <tr style={{ backgroundColor: "#333" }}>
                  <td style={{ padding: 10 }}>Rwanda – Kigali</td>
                  <td style={{ padding: 10 }}>11th – 15th November 2024</td>
                </tr>
                <tr style={{ backgroundColor: "#222" }}>
                  <td style={{ padding: 10 }}>Kenya – Nairobi</td>
                  <td style={{ padding: 10 }}>
                    18th - 22th November 2024
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#333" }}>
                  <td style={{ padding: 10 }}>South Sudan – Juba</td>
                  <td style={{ padding: 10 }}>25th – 29th November 2024</td>
                </tr>
                <tr style={{ backgroundColor: "#333" }}>
                  <td style={{ padding: 10 }}>Burundi – Bujumbura</td>
                  <td style={{ padding: 10 }}>25th – 29th November 2024</td>
                </tr>
                <tr style={{ backgroundColor: "#222" }}>
                  <td style={{ padding: 10 }}>Lubumbashi</td>
                  <td style={{ padding: 10 }}>25th – 29th November 2024</td>
                </tr>              
                <tr style={{ backgroundColor: "#222" }}>
                  <td style={{ padding: 10 }}>Tanzania – Dar-es-salaam</td>
                  <td style={{ padding: 10 }}>
                    2nd – 6th December 2024
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faqs;
