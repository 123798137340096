import React from "react";

const CallForApplication = () => {
  return (
    <section
      id="call-for-application"
      className="call-for-application section section-bg dark-background"
      style={{ padding: "60px 0", backgroundColor: "#333", color: "white" }}
    >
      {/* Section Title */}
      <div
        className="container section-title aos-init aos-animate"
        data-aos="fade-up"
        style={{ textAlign: "center", marginBottom: 40 }}
      >
        <h2 style={{ fontSize: "2.5rem", fontWeight: 700 }}> Application Closed </h2>
        <p style={{ fontSize: "1.2rem", marginTop: 10 }}>
          Application closed and only shortlisted candidates will be contact
        </p>
      </div>
      {/* End Section Title */}
      
      {/* Call for Application Button */}
      {/* <div className="container" style={{ textAlign: "center" }}>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLScPDT1uxMlgMqa0DZs3pZYTFpCz8b2qyIVSEPMMRCRFUhCdaQ/viewform?usp=sf_link"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            display: "inline-block",
            padding: "15px 30px",
            backgroundColor: "#00008B",
            color: "white",
            fontSize: "1.2rem",
            fontWeight: 700,
            borderRadius: 5,
            textDecoration: "none",
            transition: "background-color 0.3s",
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = "#00008B")}
          onMouseLeave={(e) => (e.target.style.backgroundColor = "#00008B")}
        >
          Apply Now
        </a>
      </div> */}
    </section>
  );
};

export default CallForApplication;
