import React from "react";

const TimeLine = () => {
  return (
    <section
      id="timeline"
      className="timeline section"
      style={{ padding: "60px 0", backgroundColor: "#f8f9fa" }}
    >
      <div className="container aos-init aos-animate" data-aos="fade-up" data-aos-delay={100}>
        <div className="row justify-content-center">
          {/* Timeline Card */}
          <div className="col-md-8 mb-4">
            <div
              className="card"
              style={{
                border: "none",
                borderRadius: 15,
                boxShadow: "0 6px 25px rgba(0, 0, 0, 0.15)",
                backgroundColor: "#343a40",
                transition: "transform 0.3s ease-in-out",
              }}
            >
              <div
                className="card-body"
                style={{ padding: 40, textAlign: "left", color: "white" }}
              >
                <div style={{ marginBottom: 25 }}>
                  <ul
                    style={{
                      listStyleType: "none",
                      paddingLeft: 0,
                      color: "white",
                      lineHeight: "1.8",
                    }}
                  >
                    <li
                      style={{
                        marginBottom: 20,
                        paddingLeft: 20,
                        position: "relative",
                        fontSize: "1.1rem",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          left: 0,
                          top: 5,
                          color: "#00008B",
                          fontSize: "1.5rem",
                        }}
                      >
                        •
                      </span>
                      <strong>Need Assessment:</strong> 1st – 15th September
                      2024
                    </li>
                    <li
                      style={{
                        marginBottom: 20,
                        paddingLeft: 20,
                        position: "relative",
                        fontSize: "1.1rem",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          left: 0,
                          top: 5,
                          color: "#00008B",
                          fontSize: "1.5rem",
                        }}
                      >
                        •
                      </span>
                      <strong>Call for Application for Participants:</strong>{" "}
                      26th August – 15th September 2024
                    </li>
                    <li
                      style={{
                        marginBottom: 20,
                        paddingLeft: 20,
                        position: "relative",
                        fontSize: "1.1rem",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          left: 0,
                          top: 5,
                          color: "#00008B",
                          fontSize: "1.5rem",
                        }}
                      >
                        •
                      </span>
                      <strong>Trainings in 7 EAC partner states:</strong> 30th
                      September – 15th November 2024
                    </li>
                    <li
                      style={{
                        marginBottom: 20,
                        paddingLeft: 20,
                        position: "relative",
                        fontSize: "1.1rem",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          left: 0,
                          top: 5,
                          color: "#00008B",
                          fontSize: "1.5rem",
                        }}
                      >
                        •
                      </span>
                      <strong>
                        Benchmarking and Site Visit to Makerspace or Innovation
                        Space:
                      </strong>{" "}
                      (During the Last day of In-country training)
                    </li>
                    <li
                      style={{
                        marginBottom: 20,
                        paddingLeft: 20,
                        position: "relative",
                        fontSize: "1.1rem",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          left: 0,
                          top: 5,
                          color: "#00008B",
                          fontSize: "1.5rem",
                        }}
                      >
                        •
                      </span>
                      <strong>Community of Practice (Online):</strong> 9th
                      December – 13rd December 2024
                    </li>
                    <li
                      style={{
                        paddingLeft: 20,
                        position: "relative",
                        fontSize: "1.1rem",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          left: 0,
                          top: 5,
                          color: "#00008B",
                          fontSize: "1.5rem",
                        }}
                      >
                        •
                      </span>
                      <strong>Graduation and Certificates:</strong> 13rd
                      December 2024
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* End Timeline Card */}
        </div>
        {/* End Row */}
      </div>
      {/* End Timeline Container */}
    </section>
  );
};

export default TimeLine;
